<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card @keypress.enter="createProject">
        <v-progress-linear
          indeterminate
          v-show="loadingCreate"
        ></v-progress-linear>
        <!-- заголовок -->
        <v-card-title>
          <span class="headline">Проект</span>
        </v-card-title>

        <!-- контент -->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <!-- hint="дома города" -->
                <v-text-field
                  label="Название проекта*"
                  persistent-hint
                  required
                  v-model="name"
                  :rules="prjNameRulesMix"
                  autofocus
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <!-- <v-list-item-subtitle label="asddasds">ваша ссылка на проект <b>{{ 'https://webgis.com/project/' + codename }}</b></v-list-item-subtitle> -->
                <v-text-field
                  hint="ваша ссылка на проект"
                  persistent-hint
                  disabled
                  :value="`https://${codename}.mapdev.io/`"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-show="showError">
              <v-col cols="12">
                <v-alert dense type="error">
                  Не удалось создать проект. Попробуйте позже, если проблема
                  возникла вновь, напишите нашей службе поддержки
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>

        <!-- кнопки -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Отмена
          </v-btn>
          <v-btn color="blue darken-1" text @click="createProject">
            Создать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import cyrillicToTranslit from "cyrillic-to-translit-js";
import jwt_decode from "jwt-decode";
import { getAuth } from "firebase/auth";

var quer = require("../graphql/CreateProject.gql");

export default {
  name: "create-project",
  props: ["dialog"],
  data() {
    return {
      auth: getAuth(),

      name: "",
      showError: false,
      loadingCreate: false,
    };
  },
  computed: {
    codename() {
      return cyrillicToTranslit().transform(this.name, "_");
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
    async createProject() {
      if (!this.validateName(this.name)) {
        // если не прошла валидация
        console.log("ne valid name");
        return;
      }
      if (this.loadingCreate) {
        // если уже идет создание
        return;
      }
      this.showError = false;
      this.loadingCreate = true;
      let _password = this.getPassword();
      let backgroundLink = await this.getBackgroundLink();
      let userId = await this.getUserId();
      try {
        await this.$apollo.mutate({
          mutation: quer,
          variables: {
            user_auth0_id: userId,
            name: this.name,
            codename: this.codename,
            password: _password,
            background_img: backgroundLink,
          },
        });
        this.$emit("createProject");
        this.closeDialog();
      } catch (error) {
        console.error("fail create project", error);
        this.showError = true;
        this.loadingCreate = false;
      }
      this.loadingCreate = false;
    },

    // TODO вынести в миксин
    async getUserId() {
      // возвращает id пользователя(1/2/3 и т.п. или null)
      try {
        // let tkn = await this.auth.getTokenSilently();
        let tkn = localStorage.getItem("access-token");
        let decodedTkn = jwt_decode(tkn);
        return decodedTkn["https://hasura.io/jwt/claims"]["x-hasura-user-id"];
      } catch (error) {
        console.error("can not get graphQL user id. ", error);
        return null;
      }
    },

    async getBackgroundLink() {
      // возвращает ссылку на случайную картинку или пустую строку
      try {
        let response = await axios.get("https://picsum.photos/600/220");
        // console.log(response.request.responseURL)
        return response.request.responseURL;
      } catch (error) {
        console.warn("fail get prj img", error);
        return "";
      }
    },

    validateName(name) {
      let valid = true;
      this.prjNameRulesMix.forEach((rule) => {
        if (rule(name) !== true) {
          valid = false;
        }
      });
      return valid;
    },

    getPassword() {
      let length = 8;
      let charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },
  },
};
</script>

<style lang="scss">
</style>